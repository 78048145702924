
.step-indicator {
    width: 100%;
    padding: 2rem 3rem;
    color: #fff;
  
    &__container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
    }
  
    &__steps {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
  
    &__step {
      position: relative;
      flex: 1;
      min-width: 0;
  
      &-content {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1.5rem;
      }
  
      &-number {
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
      }
  
      &-title {
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  
    &__progress {
      position: relative;
      display: flex;
      align-items: center;
    }
  
    &__line {
      position: absolute;
      left: -100%;
      right: 50%;
      height: 2px;
      background-color: #4b5563;
      z-index: 1;
  
      &.active {
        background-color: #dc2626;
      }
    }
  
    &__circle {
      position: relative;
      z-index: 2;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #4b5563;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
  
      &.active {
        background-color: #dc2626;
      }
    }
  
    &__selectors {
      display: flex;
      gap: 1rem;
    }
  }
  
  .custom-select {
    position: relative;
    min-width: 120px;
    background-color: #0a192f;
  
    &__input {
      width: 100%;
      padding: 0.5rem 2rem 0.5rem 1rem;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      color: #fff;
      font-size: 0.875rem;
      appearance: none;
      cursor: pointer;
  
      &:focus {
        outline: none;
        border-color: rgba(255, 255, 255, 0.4);
      }
  
      option {
        background-color: #0a192f;
        color: #fff;
      }
    }
  
    &__icon {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      pointer-events: none;
      font-size: 0.75rem;
    }
  }
  
  
  .sap-btn--block {
    display: flex;
    gap: 1rem;
    margin-top: 1.25rem;
  
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      padding: 0.55rem;
      border: none;
      border-radius: 0.2rem;
      transition: all 0.2s ease-in-out;
      background-color: #dc2626;
        
        &:not(:disabled):hover {
          background-color: #ef4444;
        }
      svg {
        width: 20px;
        height: 20px;
        color: #fff;
      }
  
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: #4d4c4c;
      }
    }
  
    // Styles for save buttons when on last step
    .save-btn {
      padding: 0.75rem 1.5rem;
      border-radius: 0.5rem;
      font-weight: 500;
      
      &.draft--btn {
        background-color: #1e2837;
        color: #fff;
        
        &:hover {
          background-color: #2a3749;
        }
      }
      
      &:not(.draft--btn) {
        background-color: #dc2626;
        color: #fff;
        
        &:hover {
          background-color: #ef4444;
        }
      }
    }
  }
  
  // Space utility class
  .space-x-4 {
    display: flex;
    gap: 1rem;
  }
  
  .updateFaultyBooking {
    // background-color: yellow;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: $brand-primary;
  
    h4 {
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      color: $input-value;
      padding-bottom: 1rem;
      position: relative;
      width: auto;
      border-bottom: 1px solid $activity-icon-block-border;
    }
  
    button {
      padding: 0.5rem 1rem;
      border: none;
      background-color: $brand-accent;
      cursor: pointer;
      border-radius: 15px;
      color: $input-value;
      margin-top: 10px;
      margin-left: 5px;
  
      &:hover {
        background-color: $button-primary-hover;
      }
    }
  
    input {
      margin: 5px;
      padding: 0.5rem 1rem;
      width: 300px;
      background-color: $gray-700;
      border: none;
      border-radius: 5px;
      outline: none;
      color: $input-value;
    }
  }
  
  .radioModule {
    margin: 5px;
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  
    input {
      width: auto;
      margin-right: 10px;
      width: 1.3rem;
      height: 1.3rem;
      cursor: pointer;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      border: 2px solid $special-admin;
      box-sizing: border-box;
      border-radius: 50%;
      padding: inherit;
    }
  
    label {
      width: auto;
      margin-right: 20px;
      //padding: 10px;
      border-radius: 5px;
      //background-color: $gray-700;
      cursor: pointer;
    }
  }
  
  .selectdate {
    background-color: $gray-700;
  }
  
  .dateselect2 {
    width: auto;
    display: flex;
    // background-color: red;
    align-items: center;
  
    p {
      margin-top: 10px;
      margin-right: 5px;
    }
  }
  
  #clear {
    background-color: $brand-primary;
  }
  
  .import-admin-block {
    display: flex;
    .download-sample-block {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      .download-sample--file {
        font-size: 0.9rem;
        color: #58d37d;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
  